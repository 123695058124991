<template>
  <el-dialog
      title="新增账户类型"
      :visible.sync="show"
      width="30%"
      center
      @close="close"
  >
    <el-row style="width: 90%">
      <el-form :model="accountTypeForm" :rules="rules" ref="accountTypeForm" label-width="150px">
        <el-form-item label="账户类型" prop="accountType">
          <el-input v-model="accountTypeForm.accountType" placeholder="如：全国通用账户"></el-input>
        </el-form-item>
        <el-form-item label="是否为营收类型" prop="isTrade">
          <el-switch
              v-model="accountTypeForm.isTrade"
              :active-value="1"
              :inactive-value="0"
              active-color="#13ce66"
              inactive-color="#ff4949"
          >
          </el-switch>
        </el-form-item>
        <el-form-item label="类型描述" prop="remark">
          <el-input
              type="textarea"
              v-model="accountTypeForm.remark"
              resize="none"
              :rows="5"
          ></el-input>
        </el-form-item>
      </el-form>
    </el-row>
    <span slot="footer" class="dialog-footer">
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="confirm">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  name: "account-type-add",
  props: {
    visible: {
      required: true,
      default: false,
      type: Boolean
    }
  },
  computed: {
    show: {
      get() {
        return this.visible
      },
      set(value) {
        this.$emit('update:visible', value)
      }
    }
  },
  data() {
    return {
      accountTypeForm: {
        accountType: '',
        remark: '',
        tenantCrop: localStorage.getItem('tenantCrop')
      },
      rules: {
        accountType: [
          { required: true, message: '请填写账号类型', trigger: 'change' }
        ],
        isTrade: [
          { required: true, message: '请填写营收类型', trigger: 'change' }
        ]
      }
    }
  },
  methods: {
    close() {
      this.$refs['accountTypeForm'].resetFields()
      this.show = false
    },
    confirm() {
      this.$refs['accountTypeForm'].validate((valid) => {
        if (valid) {
          this.$axios({
            url: '/accountType/addAccountType',
            method: 'post',
            params: {
              ...this.accountTypeForm
            }
          }).then(response => {
            if (response.data.code === 200) {
              this.$message.success("添加成功")
              this.$emit('close')
              this.close()
            } else {
              this.$message.error(response.data.msg)
            }
          })
        } else {
          return false
        }
      })
    }
  }
}
</script>

<style scoped>

</style>