<template>
  <el-dialog
      title="编辑账户类型"
      :visible.sync="show"
      width="30%"
      center
      @close="close"
  >
    <el-row style="width: 90%">
      <el-form :model="accountTypeForm" :rules="rules" ref="accountTypeForm" label-width="150px">
        <el-form-item label="账户类型" prop="accountType">
          <el-input v-model="accountTypeForm.accountType"></el-input>
        </el-form-item>
        <el-form-item label="是否激活" prop="isValid">
          <el-switch
              v-model="accountTypeForm.isValid"
              :active-value="1"
              :inactive-value="0"
              active-color="#13ce66"
              inactive-color="#ff4949"
          >
          </el-switch>
        </el-form-item>
        <el-form-item label="是否为营收类型" prop="isTrade">
          <el-switch
              v-model="accountTypeForm.isTrade"
              :active-value="1"
              :inactive-value="0"
              active-color="#13ce66"
              inactive-color="#ff4949"
          >
          </el-switch>
        </el-form-item>
        <el-form-item label="类型描述" prop="remark">
          <el-input
              type="textarea"
              v-model="accountTypeForm.remark"
              resize="none"
              :rows="5"
          ></el-input>
        </el-form-item>
      </el-form>
    </el-row>
    <span slot="footer" class="dialog-footer">
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="confirm">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  name: "account-type-edit",
  props: {
    visible: {
      required: true,
      default: false,
      type: Boolean
    },
    accountType: {
      type: Object,
      required: true
    }
  },
  computed: {
    show: {
      get() {
        return this.visible
      },
      set(value) {
        this.$emit('update:visible', value)
      }
    }
  },
  data() {
    return {
      accountTypeForm: {
        id: this.accountType.id,
        accountType: this.accountType.accountType,
        remark: this.accountType.remark,
        isValid: this.accountType.isValid,
        isTrade: this.accountType.isTrade,
        tenantCrop: this.accountType.tenantCrop
      },
      rules: {
        accountType: [
          { required: true, message: '请填写账号类型', trigger: 'change' }
        ],
        isValid: [
          { required: true, message: '请确认激活', trigger: 'change' }
        ]
      }
    }
  },
  methods: {
    close() {
      this.show = false
    },
    // 修改账户类型
    editAccountType(data) {
      this.$axios({
        method: 'POST',
        url: '/accountType/editAccountType',
        params: data
      }).then(response => {
        if (response.data.code !== 200) {
          this.$message.error(response.data.msg)
        } else {
          this.$message.success('修改成功')
          this.show = false
          this.$emit('close')
        }
      })
    },
    confirm() {
      this.$refs['accountTypeForm'].validate((valid) => {
        if (valid) {
          const data = {
            ...this.accountTypeForm
          }
          this.editAccountType(data)
        } else {
          return false
        }
      })
    }
  }
}
</script>

<style scoped>

</style>