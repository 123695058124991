<template>
  <div>
    <el-row>
      <el-button type="primary" @click="addAccountType">新增账户类型</el-button>
    </el-row>
    <el-row style="margin-top: 20px; margin-bottom: 20px">
      <el-table
          :data="tableData"
          style="width: 100%"
          :header-cell-style="{'text-align':'center'}"
          :cell-style="{'text-align':'center'}"
          max-height="650"
      >
        <el-table-column
            label="账户类型名"
            prop="accountType"
        >
        </el-table-column>
        <el-table-column
            label="备注信息"
            prop="remark"
        >
        </el-table-column>
        <el-table-column
            label="是否激活"
        >
          <template slot-scope="scope">
            <el-switch
                v-model="scope.row.isValid"
                :active-value="1"
                :inactive-value="0"
                @change="handleValidChange(scope.row)"
                active-color="#13ce66"
                inactive-color="#ff4949">
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column
            label="是否设置为营收账户"
        >
          <template slot-scope="scope">
            <el-switch
                v-model="scope.row.isTrade"
                :active-value="1"
                :inactive-value="0"
                @change="handleValidChange(scope.row)"
                active-color="#13ce66"
                inactive-color="#ff4949">
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button
                size="mini"
                type="primary"
                @click="handleEdit(scope.row)"
                style="margin-right: 15px"
            >编辑</el-button>
            <el-popconfirm
                title="是否确定删除？"
                @confirm="handleDelete(scope.row.id)"
            >
              <el-button
                  slot="reference"
                  size="mini"
                  type="danger">删除</el-button>
            </el-popconfirm>

          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page"
        :page-sizes="[100, 200, 300, 400]"
        :page-size="limit"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
    </el-pagination>
    <account-type-add :visible.sync="addVisible" @close="queryAccountType"></account-type-add>
    <account-type-edit v-if="editVisible" :visible.sync="editVisible" :account-type="accountType" @close="queryAccountType"></account-type-edit>
  </div>
</template>

<script>
import AccountTypeAdd from "@/pages/spend/account/account-type-add";
import AccountTypeEdit from "@/pages/spend/account/account-type-edit";
export default {
  name: "account-type-list",
  components: {AccountTypeEdit, AccountTypeAdd},
  data() {
    return {
      addVisible: false,
      editVisible: false,
      tableData: [],
      accountType: {},
      page: 1,
      limit: 100,
      total: 1
    }
  },
  mounted() {
    this.queryAccountType()
  },
  methods: {
    // 查询账户类型列表
    queryAccountType() {
      this.$axios({
        method: 'GET',
        url: '/accountType/queryAccountTypeList',
        params: {
          tenantCrop: localStorage.getItem('tenantCrop'),
          page: this.page,
          limit: this.limit
        }
      }).then(response => {
        if (response.data.code !== 200) {
          this.$message.error(response.data.msg)
        } else {
          this.tableData = response.data.data.list
          this.total = response.data.data.total
        }

      })
    },
    // 新增账户类型
    addAccountType() {
      this.addVisible = true
    },
    // 修改账户类型
    editAccountType(data) {
      this.$axios({
        method: 'POST',
        url: '/accountType/editAccountType',
        params: data
      }).then(response => {
        if (response.data.code !== 200) {
          this.$message.error(response.data.msg)
        }
      })
    },
    handleEdit(val) {
      this.accountType = val
      this.editVisible = true
    },
    handleDelete(val) {
      this.$axios({
        url: '/accountType/deleteAccountType',
        method: 'get',
        params: {
          id: val
        }
      }).then(response => {
        if (response.data.code === 200) {
          this.$message.success('删除成功')
          this.queryAccountType()
        } else {
          this.$message.error(response.data.msg)
        }
      })
    },
    handleSizeChange(val) {
      this.limit = val
      this.queryAccountType()
    },
    handleCurrentChange(val) {
      this.page = val
      this.queryAccountType()
    },
    handleValidChange(val) {
      const data = {
        id: val.id,
        isValid: val.isValid
      }
      this.editAccountType(data)
    }
  }
}
</script>

<style scoped>

</style>